<template>
  <div>
      <div class="borderTop"></div>
        <b-row class="upcomingRow">
          <template v-if="filterEvents.length === 0">
            <b-col>
              <h2 class="noEvent">
                There are no upcoming events for this sport..
              </h2>
            </b-col>
          </template>
          <b-col
            class="eve"
            lg="12"
            md="12"
            cols="12"
            v-for="(event, index) in filterEvents"
            :key="index"
            v-show="event.acf.event_done !== true"
          >
            <b-card no-body class="overflow-hidden">
              <b-row no-gutters class="desktopLayout">
                <b-col md="5" class="overHidden">
                  <template v-if="discipline === 'major-events' || event.acf.major_event === true">
                    <b-nav-item 
                      :to="
                        '/our-sports/' +
                          event.acf.discipline[0] +
                          '/events/' +
                          event.slug
                      ">
                        <div
                          :class="itemLink(event.acf.discipline[0]) + 'Back'"
                        >
                        <span :class="{purple : event.acf.discipline[0] === 'open-water'}" v-if="event.acf.discipline[0] === 'open-water'">
                          Open Water
                        </span>
                        <span v-else-if="event.acf.discipline[0] === 'artistic-swimming'" :class="{purple : event.acf.discipline[0] === 'artistic-swimming'}">
                          Artistic Swimming
                        </span>
                        <span v-else>
                          {{event.acf.discipline[0]}} 
                        </span>
                      </div>
                    </b-nav-item>
                  </template>
                  <template v-else>
                    <b-nav-item
                      :to="
                        '/our-sports/' +
                          event.acf.discipline[0] +
                          '/events/' +
                          event.slug
                      "
                    >
                      <div
                        :class="itemLink(event.acf.discipline[0]) + 'Back'"
                      >
                        <span :class="{purple : event.acf.discipline[0] === 'open-water'}" v-if="event.acf.discipline[0] === 'open-water'">
                          Open Water
                        </span>
                        <span v-else-if="event.acf.discipline[0] === 'artistic-swimming'" :class="{purple : event.acf.discipline[0] === 'artistic-swimming'}">
                          Artistic Swimming
                        </span>
                        <span v-else>
                          {{event.acf.discipline[0]}} 
                        </span>
                      </div>
                    </b-nav-item>
                  </template>
                  <b-link class="EventLink"
                    :to="
                      '/our-sports/' +
                        event.acf.discipline[0] +
                        '/events/' +
                        event.slug
                    ">
                    <b-card-img
                      :src="event.acf.image"
                      alt="Image"
                      class="rounded-0 eventImg"
                    ></b-card-img>
                  </b-link>
                </b-col>
                <b-col md="7">
                  <b-link class="EventLink"
                    :to="
                      '/our-sports/' +
                        event.acf.discipline[0] +
                        '/events/' +
                        event.slug
                    ">
                    <b-card-body>
                      <b-card-text>
                        <h3 class="dates">
                          {{ event.acf.dates }}
                        </h3>
                        <h3 class="title">
                          {{ event.acf.title }}
                        </h3>
                        <h3 class="venue">
                          {{ event.acf.venue }}
                        </h3>
                      </b-card-text>
                    </b-card-body>
                  </b-link>
                </b-col>
              </b-row>
              <b-row no-gutters class="mobileLayout">
                <b-col md="7">
                  <b-card-body>
                    <b-card-text>
                      <h3 class="dates">
                        {{ event.acf.dates }}
                      </h3>
                      <h3 class="title">
                        {{ event.acf.title }}
                      </h3>
                      <h3 class="venue">
                        {{ event.acf.venue }}
                      </h3>
                    </b-card-text>
                  </b-card-body>
                </b-col>
                <b-col md="5" class="overHidden">
                  <template v-if="discipline === 'major-events' || event.acf.major_event === true">
                    <b-nav-item 
                      :to="
                        '/our-sports/' +
                          event.acf.discipline[0] +
                          '/events/' +
                          event.slug
                      ">
                      <div
                        :class="itemLink(event.acf.discipline[0]) + 'Back'"
                      >
                        <span :class="{purple : event.acf.discipline[0] === 'open-water'}" v-if="event.acf.discipline[0] === 'open-water'">
                          Open Water
                        </span>
                        <span v-else-if="event.acf.discipline[0] === 'artistic-swimming'" :class="{purple : event.acf.discipline[0] === 'artistic-swimming'}">
                          Artistic Swimming
                        </span>
                        <span v-else>
                          {{event.acf.discipline[0]}} 
                        </span>
                      </div>
                    </b-nav-item>
                  </template>
                  <template v-else>
                    <b-nav-item
                      :to="
                        '/our-sports/' +
                          event.acf.discipline[0] +
                          '/events/' +
                          event.slug
                      "
                    >
                      <div
                        :class="itemLink(event.acf.discipline[0]) + 'Back'"
                      >
                        <span :class="{purple : discipline === 'open-water'}" v-if="discipline === 'open-water'">
                          Open Water
                        </span>
                        <span v-else-if="discipline === 'artistic-swimming'" :class="{purple : discipline === 'artistic-swimming'}">
                          Artistic Swimming
                        </span>
                        <span v-else>
                          {{event.acf.discipline[0]}} 
                        </span>
                      </div>
                    </b-nav-item>
                  </template>
                  <b-card-img
                    :src="event.acf.image"
                    alt="Image"
                    class="rounded-0 eventImg"
                  ></b-card-img>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      <div class="borderBottom"></div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "upComing",
  props: ["discipline", "total", 'pick'],
  data() {
    return {
      events: [],
    };
  },
  methods: {
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },

    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}/0${current.getMonth()+1}/${current.getFullYear()}`;
      console.log("date")
      console.log(date)
      return date;
    }
  },
  computed: {
    filterEvents: function(eve, set) {
      if (this.total === "all") {
        if (this.discipline === "none" || this.discipline === "events") {
          eve = this.events;
        } else if (this.discipline === "major-events") {
          eve = this.events.filter((dis) => dis.acf.major_event === true);
        } else if (this.discipline === "national-events" && this.pick !== "") {
          eve = this.events.filter((dis) => dis.acf.national_events === true && this.pick === dis.acf.discipline[0]);
        } else if (this.discipline === "national-events") {
          eve = this.events.filter((dis) => dis.acf.national_events === true);
        } else {
          eve = this.events.filter((dis) =>
            dis.acf.discipline.includes(this.discipline) || dis.acf.discipline[0] === 'aquatics' || dis.acf.discipline[1] === 'aquatics'
          );
          console.log("HERE")
        }
      } else {
        if (this.discipline === "none" || this.discipline === "events") {
          set = this.events;
        } else {
          set = this.events.filter((dis) =>
            dis.acf.discipline.includes(this.discipline)
          );
        }
        if (this.discipline === "none") {
          eve = set.slice(0,10); 
          // eve = set.slice(8,12); 
        } else {
          eve = set
        }
      }
      return eve;
    },
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/events?per_page=20")
      .then(({ data }) => {
        this.events = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
a.EventLink {
  color: #201546;
}
.noEvent {
  color: #1e1544;
  /* margin-left: -30px; */
  text-align: center;
  padding-bottom: 30px;
}
.purple {
  color: #fff;
}
.upcomingRow {
  margin: auto;
}
.borderTop {
  border-top: 1px solid #ededed;
  margin-bottom: 30px;
}
.borderBottom {
  border-bottom: 1px solid #ededed;
  /* margin-top: 30px; */
}
.eventImg {
  /* height: 313px; */
  height: 200px;
  object-fit: cover;
  transition: transform .2s;
}
.eventImg:hover {
  /* height: 313px; */
  height: 200px;
  object-fit: cover;
  transform: scale(1.5);
}
.overHidden {
  overflow: hidden;
}
.eve {
  border-bottom: 1px solid #ededed;
  margin-bottom: 30px;
  padding-left: 0px;
  padding-right: 0px;
}
.eve:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.card {
  /* background-color: #201546; */
  color: #201546;
  border: 0px;
  border-radius: 0;
  margin-bottom: 30px;
}
.title {
  font-weight: 600;
  font-size: 18px;
}
.title > li > a {
  color: #201546;
}
.title > li > a:hover {
  color: #009fe3;
}
.dates {
  font-size: 10pt;
  font-weight: 100;
  padding: 20px 0;
}
.venue {
  font-size: 16px;
  padding: 10px 0;
}
.swimming {
  color: #009fe3;
}
.para-swimming {
  color: #afca0b;
}
.water-polo {
  color: #349980;
}
.masters {
  color: #ff9901;
}
.artistic-swimming {
  color: #f80240;
}
.diving {
  color: #6ec9df;
}
.open-water {
  color: #5c439c;
}
.swimmingBack {
  background-color: #009fe3;
  color: #201546;
  text-transform: capitalize;
  padding: 10px;
  display: inline-block;
  border-radius: 0px 0px 15px 0px;
  position: absolute;
  font-size: 10pt;
  padding-right: 25px;
  font-weight: 600;
  z-index: 99;
}
.para-swimmingBack {
  background-color: #afca0b;
  color: #201546;
  text-transform: capitalize;
  padding: 10px;
  display: inline-block;
  border-radius: 0px 0px 15px 0px;
  position: absolute;
  font-size: 10pt;
  padding-right: 25px;
  font-weight: 600;
  z-index: 99;
}
.water-poloBack {
  background-color: #349980;
  color: #201546;
  text-transform: capitalize;
  padding: 10px;
  display: inline-block;
  border-radius: 0px 0px 15px 0px;
  position: absolute;
  font-size: 10pt;
  padding-right: 25px;
  font-weight: 600;
  z-index: 99;
}
.mastersBack {
  background-color: #ff9901;
  color: #201546;
  text-transform: capitalize;
  padding: 10px;
  display: inline-block;
  border-radius: 0px 0px 15px 0px;
  position: absolute;
  font-size: 10pt;
  padding-right: 25px;
  font-weight: 600;
  z-index: 99;
}
.aquaticsBack {
  background-color: #000;
  color: #fff;
  text-transform: capitalize;
  padding: 10px;
  display: inline-block;
  border-radius: 0px 0px 15px 0px;
  position: absolute;
  font-size: 10pt;
  padding-right: 25px;
  font-weight: 600;
  z-index: 99;
}
.artistic-swimmingBack {
  background-color: #f80240;
  color: #201546;
  text-transform: capitalize;
  padding: 10px;
  display: inline-block;
  border-radius: 0px 0px 15px 0px;
  position: absolute;
  font-size: 10pt;
  padding-right: 25px;
  font-weight: 600;
  z-index: 99;
}
.divingBack {
  background-color: #6ec9df;
  color: #201546;
  text-transform: capitalize;
  padding: 10px;
  display: inline-block;
  border-radius: 0px 0px 15px 0px;
  position: absolute;
  font-size: 10pt;
  padding-right: 25px;
  font-weight: 600;
  z-index: 99;
}
.open-waterBack {
  background-color: #5c439c;
  color: #201546;
  text-transform: capitalize;
  padding: 10px;
  display: inline-block;
  border-radius: 0px 0px 15px 0px;
  position: absolute;
  font-size: 10pt;
  padding-right: 25px;
  font-weight: 600;
  z-index: 99;
}
.titleBtn {
  height: 66px;
  border-radius: 0px 33px 33px 0px;
  font-size: 16px;
  font-weight: bold;
  font-weight: bold;
  color: #fff;
  margin-top: -33px;
  position: absolute;
  padding: 20px;
  text-transform: uppercase;
  padding-right: 75px;
  -webkit-transition: padding-right 0.5s;
  transition: padding-right 0.5s;
}
.titleBtn:hover {
  padding-right: 175px;
}
.titleBtn > .nav-item > a {
  padding: 0;
  color: #fff;
}
.ourContainer {
  max-width: 1350px;
  margin: 0 auto;
}
.nav-item > a {
  padding: 0;
}
.noEvent {
  color: #bfbfbf;
  margin-left: -30px;
}
@media only screen and (min-width: 501px) {
  .mobileLayout {
    display: none!important;
  }
}
@media only screen and (max-width: 500px) {
  .desktopLayout {
    display: none!important;
  }
  .row.no-gutters {
    display: flex;
    flex-flow: column-reverse;
  }
  .eventImg {
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .titleBtn {
    font-size: 14px;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    padding: 20px 0;
    padding-top: 0px!important;
  }
  .venue {
    font-size: 16px;
    padding: 10px 0;
    padding-bottom: 30px;
  }
}
</style>
