import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";
import HomeTest from "../views/hometest.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home-test",
    name: "home-test",
    component: HomeTest,
    props: true
  },
  {
    path: "/",
    name: "home",
    component: Home,
    props: true
  },
  { path: "/home", redirect: { name: "home" } },
  { path: "/home/:slug", redirect: "/:slug" },
  { path: "/home/:slug/:slug1", redirect: "/:slug/:slug1" },
  { path: "/home/:slug/:slug1/:slug2", redirect: "/:slug/:slug1/:slug2" },

  {
    path: "/about-us",
    name: "about-us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/event-calendar",
    name: "event-calendar",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/dynamic-forms",
    name: "dynamic-forms",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/submit-a-record-online",
    name: "submit-a-record-online",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/job-forms",
    name: "job-forms",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/jobs/job-forms",
    name: "job-forms",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/test-page",
    name: "test-page",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/about-our-sport",
    name: "about-our-sport",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/governance",
    name: "governance",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/meet-the-team",
    name: "meet-the-team",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/meet-the-team/:slug",
    name: "meet-the-team",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/meettheteamPage.vue"),
    props: true
  },
  {
    path: "/about-us/equality",
    name: "equality",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/partners",
    name: "partners",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/code-of-ethics",
    name: "code-of-ethics",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/history-of-our-sport",
    name: "history-of-our-sport",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/history-of-our-sport/hall-of-fame",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/history-of-our-sport/hall-of-fame/:slug",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hallOfFamePage.vue"),
    props: true
  },
  {
    path: "/about-us/trophy-cabinet",
    name: "trophy-cabinet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/about-us/governance/hall-of-fame",
  //   name: "hall-of-fame",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
  //   props: true
  // },
  // {
  //   path: "/about-us/governance/hall-of-fame/:slug",
  //   name: "hall-of-fame",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/hallOfFamePage.vue"),
  //   props: true
  // },
  {
    path: "/about-us/agm",
    name: "agm",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/awards-dinner",
    name: "awards-dinner",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/board-and-council-minutes",
    name: "board-and-council-minutes",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us/data-protection",
    name: "data-protection",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/inspired-to-try/",
    name: "inspired-to-try",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports",
    name: "our-sports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/disciplines.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming",
    name: "swimming",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discMain.vue"),
    props: true
  },
  { path: "/swimming", redirect: { name: "swimming" } },
  {
    path: "/our-sports/swimming/events",
    name: "swimming-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/our-sports/:dis/events/:slug",
    name: "event",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/eventPage.vue"),
    props: true
  },
  {
    path: "/our-sports/aquatics/events/:slug",
    name: "event",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/eventPage.vue"),
    props: true
  },
  {
    path: "/our-sports/aquatics/scottish-swimming-awards-evening",
    name: "scottish-swimming-awards-evening",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/awards-evening",
    name: "awards-evening",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/aquatics/events",
    name: "aquatics-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/news",
    name: "swimming-news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
    props: true
  },
  {
    path: "/our-sports/:dis/news/:slug",
    name: "swimming-news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsStory.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/results",
    name: "swimming-results",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/results.vue"),
    props: true
  },
  {
    path: "/our-sports/:slug/records",
    name: "records",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/our-sports/swimming/records/:slug",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/recordsPage.vue"),
  //   props: true
  // },

  {
    path: "/our-sports/swimming/profiles",
    name: "swimming-profiles",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/profiles.vue"),
    props: true
  },
  {
    path: "/our-sports/:dis/profiles/:slug",
    name: "profiles-page",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/profilePage.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/contacts",
    name: "swimming-contacts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contacts.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/squads",
    name: "squads",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/rankings",
    name: "rankings",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/swimsuits",
    name: "swimsuits",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/resources",
    name: "swimming-resources",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/resources.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/resources",
    name: "swimming-resources",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/resources.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/swimming-important-documents",
    name: "swimming-important-documents",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/swimming-anti-doping",
    name: "swimming-anti-doping",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/swimming-travel-guidelines",
    name: "swimming-travel-guidelines",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/swimming-trophy-cabinet",
    name: "swimming-trophy-cabinet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/:slug/hall-of-fame",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/swimming/hall-of-fame/:slug",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hallOfFamePage.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming",
    name: "para-swimming",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discMain.vue"),
    props: true
  },
  { path: "/para-swimming", redirect: { name: "para swimming" } },

  {
    path: "/our-sports/para-swimming/events",
    name: "para-swimming-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming/news",
    name: "para-swimming-news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming/results",
    name: "para-swimming-results",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/results.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming/classification",
    name: "classification",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/our-sports/:slug/records",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
  //   props: true
  // },
  // {
  //   path: "/our-sports/para-swimming/records/:slug",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/recordsPage.vue"),
  //   props: true
  // },
  {
    path: "/our-sports/para-swimming/profiles",
    name: "para-swimming-profiles",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/profiles.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming/para-swimming-squads",
    name: "para-swimming-squads",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming/contacts",
    name: "para-swimming-contacts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contacts.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming/resources",
    name: "para-swimming-resources",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/resources.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming/para-swimming-important-documents",
    name: "para-swimming-important-documents",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming/para-swimming-anti-doping",
    name: "para-swimming-anti-doping",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming/para-swimming-travel-guidelines",
    name: "para-swimming-travel-guidelines",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming/para-swimming-trophy-cabinet",
    name: "para-swimming-trophy-cabinet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/:slug/hall-of-fame",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/para-swimming/hall-of-fame/:slug",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hallOfFamePage.vue"),
    props: true
  },
  {
    path: "/our-sports/diving",
    name: "diving",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discMain.vue"),
    props: true
  },
  { path: "/diving", redirect: { name: "diving" } },

  {
    path: "/our-sports/diving/events",
    name: "diving-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/our-sports/diving/news",
    name: "diving-news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
    props: true
  },
  {
    path: "/our-sports/diving/diving-squads",
    name: "diving-squads",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/diving/results",
    name: "diving-results",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/results.vue"),
    props: true
  },
  // {
  //   path: "/our-sports/:slug/records",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
  //   props: true
  // },
  // {
  //   path: "/our-sports/diving/records/:slug",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/recordsPage.vue"),
  //   props: true
  // },
  {
    path: "/our-sports/diving/profiles",
    name: "diving-profiles",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/profiles.vue"),
    props: true
  },
  {
    path: "/our-sports/diving/contacts",
    name: "diving-contacts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contacts.vue"),
    props: true
  },
  {
    path: "/our-sports/diving/resources",
    name: "diving-resources",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/resources.vue"),
    props: true
  },
  {
    path: "/our-sports/diving/diving-important-documents",
    name: "diving-important-documents",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/diving/diving-anti-doping",
    name: "diving-anti-doping",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/diving/diving-travel-guidelines",
    name: "diving-travel-guidelines",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/diving/diving-trophy-cabinet",
    name: "diving-trophy-cabinet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/:slug/hall-of-fame",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/diving/hall-of-fame/:slug",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hallOfFamePage.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo",
    name: "water-polo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discMain.vue"),
    props: true
  },
  { path: "/water-polo", redirect: { name: "water polo" } },

  {
    path: "/our-sports/water-polo/events",
    name: "water-polo-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/water-polo-squads",
    name: "water-polo-squads",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/news",
    name: "water-polo-news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/results",
    name: "water-polo-results",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/results.vue"),
    props: true
  },
  // {
  //   path: "/our-sports/:slug/records",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
  //   props: true
  // },
  {
    path: "/our-sports/water-polo/rules-of-water-polo",
    name: "rules-of-water-polo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/contacts/committee-information",
    name: "committee-information",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path:
      "/our-sports/water-polo/water-polo-important-documents/water-polo-team-manager-handbook",
    name: "water-polo-team-manager-handbook",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/national-squads",
    name: "national-squads",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/our-sports/water-polo/records/:slug",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/recordsPage.vue"),
  //   props: true
  // },
  {
    path: "/our-sports/water-polo/profiles",
    name: "water-polo-profiles",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/profiles.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/contacts",
    name: "water-polo-contacts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contacts.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/resources",
    name: "water-polo-resources",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/resources.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/water-polo-important-documents",
    name: "water-polo-important-documents",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/water-polo-anti-doping",
    name: "water-polo-anti-doping",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/water-polo-travel-guidelines",
    name: "water-polo-travel-guidelines",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/water-polo-trophy-cabinet",
    name: "water-polo-trophy-cabinet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/:slug/hall-of-fame",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/water-polo/hall-of-fame/:slug",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hallOfFamePage.vue"),
    props: true
  },
  {
    path: "/our-sports/masters",
    name: "masters",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discMain.vue"),
    props: true
  },
  { path: "/masters", redirect: { name: "masters" } },

  {
    path: "/our-sports/masters/events",
    name: "masters-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/our-sports/masters/decathlon",
    name: "decathlon",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/masters/news",
    name: "masters-news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
    props: true
  },
  {
    path: "/our-sports/masters/results",
    name: "masters-results",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/results.vue"),
    props: true
  },
  // {
  //   path: "/our-sports/:slug/records",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
  //   props: true
  // },
  // {
  //   path: "/our-sports/masters/records/:slug",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/recordsPage.vue"),
  //   props: true
  // },
  {
    path: "/our-sports/masters/profiles",
    name: "masters-profiles",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/profiles.vue"),
    props: true
  },
  {
    path: "/our-sports/masters/contacts",
    name: "masters-contacts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contacts.vue"),
    props: true
  },
  {
    path: "/our-sports/masters/resources",
    name: "masters-resources",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/resources.vue"),
    props: true
  },
  {
    path: "/our-sports/masters/masters-important-documents",
    name: "masters-important-documents",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/masters/masters-anti-doping",
    name: "masters-anti-doping",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/masters/masters-travel-guidelines",
    name: "masters-travel-guidelines",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/masters/masters-trophy-cabinet",
    name: "masters-trophy-cabinet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/:slug/hall-of-fame",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/masters/hall-of-fame/:slug",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hallOfFamePage.vue"),
    props: true
  },
  {
    path: "/our-sports/open-water",
    name: "open-water",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discMain.vue"),
    props: true
  },
  { path: "/open-water", redirect: { name: "open water" } },

  {
    path: "/our-sports/open-water/events",
    name: "open-water-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/our-sports/open-water/news",
    name: "open-water-news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
    props: true
  },
  {
    path: "/our-sports/open-water/results",
    name: "open-water-results",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/results.vue"),
    props: true
  },
  // {
  //   path: "/our-sports/:slug/records",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
  //   props: true
  // },
  // {
  //   path: "/our-sports/open-water/records/:slug",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/recordsPage.vue"),
  //   props: true
  // },
  {
    path: "/our-sports/open-water/open-water-squads",
    name: "open-water-squads",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/open-water/profiles",
    name: "open-water-profiles",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/profiles.vue"),
    props: true
  },
  {
    path: "/our-sports/open-water/contacts",
    name: "open-water-contacts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contacts.vue"),
    props: true
  },
  {
    path: "/our-sports/open-water/contacts/open-water-committee-information",
    name: "open-water-committee-information",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/open-water/resources",
    name: "open-water-resources",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/resources.vue"),
    props: true
  },
  {
    path: "/our-sports/open-water/open-water-important-documents",
    name: "open-water-important-documents",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/open-water/open-water-anti-doping",
    name: "open-water-anti-doping",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/open-water/open-water-travel-guidelines",
    name: "open-water-travel-guidelines",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/open-water/trophy-cabinet",
    name: "trophy-cabinet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/:slug/hall-of-fame",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/our-sports/open-water/hall-of-fame/:slug",
  //   name: "hall-of-fame",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/hallOfFamePage.vue"),
  //   props: true
  // },
  {
    path: "/our-sports/open-water/open-water-water-safety",
    name: "open-water-water-safety",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/artistic-swimming",
    name: "artistic-swimming",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/discMain.vue"),
    props: true
  },
  { path: "/artistic-swimming", redirect: { name: "artistic swimming" } },

  {
    path: "/our-sports/artistic-swimming/events",
    name: "artistic-swimming-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/our-sports/artistic-swimming/news",
    name: "artistic-swimming-news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
    props: true
  },
  {
    path: "/our-sports/artistic-swimming/results",
    name: "artistic-swimming-results",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/results.vue"),
    props: true
  },
  {
    path: "/our-sports/:slug/records",
    name: "records",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/our-sports/artistic-swimming/records/:slug",
  //   name: "records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/recordsPage.vue"),
  //   props: true
  // },
  {
    path: "/our-sports/artistic-swimming/profiles",
    name: "artistic-swimming-profiles",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/profiles.vue"),
    props: true
  },
  {
    path: "/our-sports/artistic-swimming/contacts",
    name: "artistic-swimming-contacts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contacts.vue"),
    props: true
  },
  {
    path: "/our-sports/artistic-swimming/resources",
    name: "artistic-swimming-resources",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/resources.vue"),
    props: true
  },
  {
    path: "/our-sports/artistic-swimming/artistic-swimming-important-documents",
    name: "artistic-swimming-important-documents",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/artistic-swimming/artistic-swimming-anti-doping",
    name: "artistic-swimming-anti-doping",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/artistic-swimming/artistic-swimming-travel-guidelines",
    name: "artistic-swimming-travel-guidelines",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/artistic-swimming/artistic-swimming-trophy-cabinet",
    name: "artistic-swimming-trophy-cabinet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/:slug/hall-of-fame",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/our-sports/artistic-swimming/hall-of-fame/:slug",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/hallOfFamePage.vue"),
    props: true
  },
  {
    path: "/compete",
    name: "compete",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/compete/olympic-paralympic",
    name: "olympic-paralympic",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/compete/commonwealth-games-compete",
    name: "commonwealth-games",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/compete/world-championships",
    name: "world-championships",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/compete/european-championships",
    name: "european-championships",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events",
    name: "events",
    component: () =>
      // import(/* webpackChunkName: "about" */ "../views/events.vue"),
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/events/major-events",
  //   name: "major-events",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/events.vue"),
  //   props: true,
  // },
  {
    path: "/events/hall-of-fame",
    name: "hall-of-fame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/events/:slug",
  //   name: "events",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/eventPage.vue"),
  //   props: true
  // },
  // {
  //   path: "/events/hall-of-fame/:slug",
  //   name: "hall-of-fame",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/hallOfFamePage.vue"),
  //   props: true
  // },
  // {
  //   path: "/events/anti-doping",
  //   name: "anti-doping",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
  //   props: true,
  // },
  {
    path: "/events/travel-guidelines",
    name: "travel-guidelines",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/events/records",
  //   name: "events-records",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/records.vue"),
  //   props: true,
  // },
  {
    path: "/events/national-events",
    name: "events",
    component: () =>
      // import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/events/international-events",
    name: "international-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/district-events",
    name: "district-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/other-events",
    name: "other-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/events/trophy-cabinet",
    name: "trophy-cabinet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/events-calendar",
    name: "events-calendar",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/events/swimming-events-calendar",
    name: "swimming-events-calendar",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/events.vue"),
    props: true
  },
  {
    path: "/events/events-calendar/aquatic-events",
    name: "aquatic-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/events-calendar/learning-and-development",
    name: "learning-and-development",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/events-calendar/networking-events",
    name: "networking-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/events-calendar/qualifications",
    name: "qualifications",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/international-events/olympics",
    name: "olympics",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/international-events/paralympics",
    name: "paralympics",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/international-events/commonwealth-games",
    name: "commonwealth-games",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/international-events/world-championships",
    name: "world-championships",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/international-events/european-championships",
    name: "european-championships",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/district-events/north",
    name: "north",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/district-events/south",
    name: "south",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/district-events/middle",
    name: "middle",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/district-events/east",
    name: "east",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/events/district-events/west",
    name: "west",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/swim-social",
    name: "swim-social",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/swim-social/coached-classes",
    name: "coached-classes",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/swim-social/becoming-a-master",
    name: "becoming-a-master",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim",
    name: "learn-to-swim",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  // {
  //   path: "/learn-to-swim/why-swim/water-safety",
  //   name: "water-safety",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
  //   props: true
  // },
  {
    path: "/learn-to-swim/water-safety",
    name: "water-safety",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/school-swimming-framework",
    name: "school-swimming-framework",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/school-swimming/framework",
    name: "school-swimming-framework",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/learn-to-swim-framework",
    name: "learn-to-swim-framework",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/learn-to-swim-framework-2",
    name: "learn-to-swim-framework-2",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/early-years",
    name: "early-years",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/adult-learn-to-swim",
    name: "adult-learn-to-swim",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  // {
  //   path: "/learn-to-swim/swim-school",
  //   name: "swim-school",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
  //   props: true,
  // },
  {
    path: "/learn-to-swim/rewards-and-recognition",
    name: "rewards-and-recognition",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/ambassadors",
    name: "ambassadors",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/where-to-swim",
    name: "where-to-swim",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/where-to-swim/swimming-lesson-providers",
    name: "swimming-lesson-providers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  // {
  //   path: "/learn-to-swim/where-to-swim/framework",
  //   name: "framework",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
  //   props: true
  // },
  {
    path: "/learn-to-swim/framework",
    name: "framework",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/where-to-swim/leisure-trusts",
    name: "leisure-trusts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/where-to-swim/affiliated-swim-schools",
    name: "affiliated-swim-schools",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/where-to-swim/can-leisure-trust-links",
    name: "can-leisure-trust-links",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/why/early-years",
    name: "early-years",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/why-swim/benefits-of-swimming",
    name: "benefits-of-swimming",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/why-swim/faq-learn",
    name: "faq-learn",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/why-swim",
    name: "why-swim",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/why-swim-2",
    name: "why-swim",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  // {
  //   path: "/learn-to-swim/why-swim-benefits-of-swimming/why-swim",
  //   name: "why-swim",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
  //   props: true,
  // },
  {
    path: "/learn-to-swim/stages",
    name: "stages",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/stages-2",
    name: "stages",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/stages/early-years",
    name: "early-years",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/stages/preschool",
    name: "preschool",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/stages/primary",
    name: "primary",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/stages/swim-skills",
    name: "swim-skills",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/stages/club-ready",
    name: "club-ready",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/stages/club-swimming",
    name: "club-swimming",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/stages/adult",
    name: "adult",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/stages/inclusion",
    name: "inclusion",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/what-to-expect",
    name: "what-to-expect",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/what-to-expect/technique-videos",
    name: "technique-videos",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/what-to-expect/games-fun-videos",
    name: "games-fun-videos",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/what-to-expect/games-for-the-pool/:slug",
    name: "games-for-the-pool",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/games.vue"),
    props: true
  },
  {
    path: "/learn-to-swim/what-to-expect/awards-and-recognition",
    name: "awards-and-recognition",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path:
      "/learn-to-swim/what-to-expect/opportunities-after-learn-to-swim-clubs",
    name: "opportunities-after-learn-to-swim-clubs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue"),
    props: true
  },
  {
    path:
      "/media-browser-files",
    name: "media-browser-files",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/learn-to-swim/challenge-duncan",
  //   name: "challenge-duncan",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
  //   props: true,
  // },
  {
    path: "/clubs",
    name: "clubs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/set-up-your-club",
    name: "set-up-your-club",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/set-up-your-club/club-affiliation-guidelines",
    name: "club-affiliation-guidelines",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/set-up-your-club/club-finder",
    name: "club-finder",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/inspired-to-try",
    name: "inspired-to-try",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/manage-your-club",
    name: "manage-your-club",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/manage-your-club/complaints",
    name: "complaints",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/governance-club",
    name: "governance-club",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/manage-your-club/insurance",
    name: "insurance",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/manage-your-club/risk-assessments",
    name: "risk-assessments",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/manage-your-club/rti-guide",
    name: "rti-guide",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/develop-your-club",
    name: "develop-your-club",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/develop-your-club/club-framework",
    name: "club-framework",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/develop-your-club/club-accreditation-scheme",
    name: "club-accreditation-scheme",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/develop-your-club/club-marketing-toolkit",
    name: "club-marketing-toolkit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/wellbeing-protection",
    name: "wellbeing-protection",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/facilities",
    name: "facilities",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/facilities/saveourpools",
    name: "saveourpools",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/facilities/facilities-strategy",
    name: "facilities-strategy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/active-communities",
    name: "active-communities",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/active-communities/value-of-swimming",
    name: "value-of-swimming",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/active-communities/benefits-of-swimming",
    name: "benefits-of-swimming",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/active-communities/physical-health",
    name: "physical-health",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/active-communities/mental-health",
    name: "mental-health",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/active-communities/wellness-and-wellbeing",
    name: "wellness-and-wellbeing",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/active-communities/outdoor-swimming",
    name: "outdoor-swimming",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/active-communities/nutrition",
    name: "nutrition",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers",
    name: "volunteers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/volunteerHub.vue"),
    props: true
  },
  {
    path: "/volunteers/why-volunteer",
    name: "why-volunteer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/volunteer-strategy-and-toolkit",
    name: "volunteer-strategy-and-toolkit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/volunteer-in-clubs",
    name: "volunteer-in-clubs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/volunteer-at-events",
    name: "volunteer-at-events",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/volunteers-in-officiating",
    name: "volunteers-in-officiating",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/swimming-officiating",
    name: "swimming-officiating",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/open-water-officiating",
    name: "open-water-officiating",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/young-volunteer-programme",
    name: "young-volunteer-programme",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/volunteers-useful-documents",
    name: "volunteers-useful-documents",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/recruitment-and-retention",
    name: "recruitment-and-retention",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/volunteer-stories",
    name: "volunteer-stories",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/volunteer-faq",
    name: "volunteer-faq",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/volunteer-roles",
    name: "volunteer-roles",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/volunteers/club-young-volunteer-programme",
    name: "club-young-volunteer-programme",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/club-young-volunteer-programme",
    name: "club-young-volunteer-programme",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/club-young-volunteer-programme/club-young-leader-squad",
    name: "club-young-leader-squad",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/club-young-volunteer-programme/club-young-technical-official",
    name: "club-young-technical-official",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/club-young-volunteer-programme/club-young-events-co-ordinator",
    name: "club-young-events-co-ordinator",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/club-young-volunteer-programme/club-young-ambassador-programme",
    name: "club-young-ambassador-programme",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/club-young-volunteer-programme/club-poolside-helper",
    name: "club-poolside-helper",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/club-of-the-year",
    name: "club-of-the-year",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/club-marketing-toolkit",
    name: "club-marketing-toolkit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching",
    name: "coaching-and-teaching",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/coachingandteaching.vue"
      ),
    props: true
  },
  {
    path: "/coaching-and-teaching/teaching",
    name: "teaching",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/education-policies",
    name: "education-policies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/learning-and-development",
    name: "learning-and-development",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/teaching/becoming-a-teacher",
    name: "becoming-a-teacher",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/teaching/teaching-qualifications",
    name: "teaching-qualifications",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/teaching/teaching-learning-and-development",
    name: "teaching-learning-and-development",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/teaching/the-teaching-framework",
    name: "the-teaching-framework",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/teaching/teacher-stories",
    name: "teacher-stories",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/teaching/jobs-in-teaching-link",
    name: "jobs-in-teaching-link",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true,
    redirect: "/jobs"
  },
  {
    path: "/coaching-and-teaching/teaching/how-to-run-a-course",
    name: "how-to-run-a-course",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/teaching/teaching-qualification-comparison",
    name: "teaching-qualification-comparison",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/coaching",
    name: "coaching",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/coaching/becoming-a-coach",
    name: "becoming-a-coach",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/coaching/the-coaching-framework",
    name: "the-coaching-framework",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/coaching/coaching-qualification-comparison",
    name: "coaching-qualification-comparison",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/coaching/coaching-stories",
    name: "coaching-stories",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/coaching/coaching-qualifications",
    name: "coaching-qualifications",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/coaching/coaching-learning-and-development",
    name: "coaching-learning-and-development",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/coaching/jobs-in-coaching",
    name: "jobs-in-coaching",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/coaching/how-to-run-a-course-coaching",
    name: "how-to-run-a-course-coaching",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/coaching-and-teaching/course-calendar",
  //   name: "course-calendar",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/courseevents.vue"),
  //   props: true,
  // },
  {
    path: "/coaching-and-teaching/course-calendar",
    name: "course-calendar",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/courseevents.vue"),
    props: true
  },
  {
    path: "/coaching-and-teaching/course-calendar/find-a-course",
    name: "find-a-course",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/courseevents.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection",
    name: "wellbeing-and-protection",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/conference-information",
    name: "conference-information",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/coaching-and-teaching/course-calendar/:slug",
  //   name: "course-calendar",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/courseeventPage.vue"),
  //   props: true
  // },
  {
    path: "/wellbeing-and-protection",
    name: "wellbeing-and-protection",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/conference-information",
    name: "conference-information",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection-children",
    name: "wellbeing-and-protection-children",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/wellbeing-and-protection-children",
    name: "wellbeing-and-protection-children",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/wellbeing-and-protection-adults",
    name: "wellbeing-and-protection-adults",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/other-useful-resources-and-online-safety",
    name: "other-useful-resources-and-online-safety",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/wellbeing-and-protection-policy",
    name: "wellbeing-and-protection-policy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/wellbeing-protection-policy-adults",
    name: "wellbeing-protection-policy-adults",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },

  {
    path: "/wellbeing-and-protection/wellbeing-and-protection-children",
    name: "wellbeing-and-protection-children",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/wellbeing-and-protection-adults",
    name: "wellbeing-and-protection-adults",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/other-useful-resources-and-online-safety",
    name: "other-useful-resources-and-online-safety",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/become-a-wellbeing-and-protection-officer",
    name: "become-a-wellbeing-and-protection-officer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/wellbeing-protection-officer-support",
    name: "wellbeing-protection-officer-support",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/wellbeing-and-protection-officer-support",
    name: "wellbeing-and-protection-officer-support",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/pvg-information",
    name: "pvg-information",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/wellbeing-and-protection-training",
    name: "wellbeing-and-protection-training",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/wellbeing-protection-officer-support",
    name: "wellbeing-protection-officer-support",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/mental-health",
    name: "mental-health",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/mental-health-training",
    name: "mental-health-training",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/useful-resources-and-online-safety",
    name: "useful-resources-and-online-safety",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/reporting-a-concern",
    name: "reporting-a-concern",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/wellbeing-and-protection/who-to-contact",
    name: "who-to-contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/membership",
    name: "membership",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/clubs/membership-secretary-support",
    name: "/membership-secretary-support",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/membership/membership-check",
    name: "membership-check",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/membership/membership-check.aspx",
    redirect: {
      name: "membership-check"
    }
  },
  {
    path: "/membership/members-portal",
    beforeEnter() {
      window.open("https://scottishswimming.justgo.com/Account.mvc/login", 
      '_blank');
    }
  },
  {
    path: "/membership/benefits-of-membership",
    name: "benefits-of-membership",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/membership/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/membership/find-a-club",
    name: "find-a-club",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/membership/protecting-your-data",
    name: "protecting-your-data",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/membership/scottish-non-residents",
    name: "scottish-non-residents",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/membership/mental-health-wellbeing",
    name: "mental-health-wellbeing",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/membership/get-involved-and-volunteer",
    name: "get-involved-and-volunteer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/education",
    name: "education",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/education/tutor-information",
    name: "tutor-information",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/education/education-policies",
    name: "education-policies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/education/qualifications-comparison",
    name: "qualifications-comparison",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/education/licensing-and-benefits",
    name: "licensing-and-benefits",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  // {
  //   path: "/education/learning-and-development",
  //   name: "learning-and-development",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
  //   props: true
  // },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
    props: true
  },
  {
    path: "/news/:slug",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsStory.vue"),
    props: true
  },
  {
    path: "/find-a-club",
    name: "find-a-club",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/contact-us/staff-contacts",
    name: "staff-contacts",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/generation-swim",
    name: "generation-swim",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/sponsorship",
    name: "sponsorship",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/sitemap",
    name: "sitemap",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/search.vue"),
    props: true
  },
  {
    path: "/jobs/other-jobs",
    name: "other-jobs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/awards-evening",
    name: "awards-evening",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/jobs/matt-test-job",
    name: "matt-test-job",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPages.vue"),
    props: true
  },
  {
    path: "/jobs",
    name: "jobs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/jobs.vue"),
    props: true
  },
  // {
  //   path: "/jobs/job-vacancies-across-the-sport",
  //   name: "job-vacancies-across-the-sport",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/jobs.vue"),
  //   props: true,
  // },
  {
    path: "/jobs/:id",
    name: "jobs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/jobsPage.vue"),
    props: true
  },
  {
    path: "/preview/:id",
    name: "preview",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/preview.vue"),
    props: true
  },
  {
    path: "/:slug",
    name: "",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/page.vue"),
    props: true
  },
  {
    path: "/:slug/:id",
    name: "Test",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mainPagesNew.vue"),
    props: true
  }
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.afterEach(to => {
  localStorage.setItem('lastRoute', to.path)
});


router.beforeEach((to, from, next) => {
  /* must call `next` */
  // let route_records = routes.getRoutes();
  // console.log(route_records);
  let route_check = router.resolve(to.path);
  if (route_check.resolved.matched.length > 0) {
    //the route is exists.
    return next();
  } else {
    // console.log("resolved");
    // console.log(route_check);
    // console.log("to");
    // console.log(to);
    // console.log("from");
    // console.log(from);
    //the route does not exists.
    // let slug_name = to.path.split("/").pop();
    let slug_name = to.path;
    if (slug_name.endsWith("/")) {
      slug_name = slug_name.slice(0, -1);
    }
    if (slug_name.includes('/learn-to-swim')) {
      slug_name = slug_name.split("/").pop();
      let route_options = {
        // path: "/" + element.slug,
        path: to.path,
        name: slug_name,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/learnToswimPages.vue")
      };
      // console.log("route_options");
      // console.log(route_options);
      router.addRoute(route_options);
      // next();
      next({ path: to.path });
      // return next();     
    } else {
      slug_name = slug_name.split("/").pop();
      let route_options = {
        // path: "/" + element.slug,
        path: to.path,
        name: slug_name,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/mainPages.vue")
      };
      // console.log("route_options");
      // console.log(route_options);
      router.addRoute(route_options);
      // next();
      next({ path: to.path });
      // return next();
    }
  }
});

export default router;