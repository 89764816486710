<template>
    <div class="pad"> 
      <b-row>
          <template v-if="this.$route.name === 'home'">
            <b-col lg='6'      
              v-for="(post, index) in posts.slice(0, 3)" 
              :key='index'
              class="featuredCols"
            >
              <b-card no-body class="overflow-hidden">
                <b-row no-gutters>
                  <b-col md="8">
                    <b-card-body>
                      <b-card-text>
                        <h3 class="datescard">
                          {{ post.date }}
                        </h3>
                        <h3 class="titlecard">
                          {{ post.title.rendered }}
                        </h3>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                  <b-col md="4">
                    <b-card-img
                      :src="post.better_featured_image.source_url"
                      alt="Image"
                      class="rounded-0 eventImg"
                    ></b-card-img>
                  </b-col>
                </b-row>
              </b-card>
              <b-nav-item :to="post.link" style="margin-left: -16px;">
                <div
                  class="title"
                  :style="'background-color:#DB044E;'"
                >
                  <span class="db">Featured News</span>
                </div>
              </b-nav-item>
            </b-col>
          </template>
      </b-row>


      <template v-if="this.$route.name === 'home'">
        <b-row>
          <b-col
            class="disCol"
            lg="3"
            md="12"
            v-for="(dis, index) in disciplines.slice().reverse()"
            :key="index"
          >
            <template v-if="dis.acf.title.includes('Find')">
              <b-nav-item to="/find-a-club">
                <div>
                  <div class="">
                    <b-img alt="image" :src="dis.acf.image">
                    </b-img>
                    <div class="acfTitle">
                      {{dis.acf.title}}
                    </div>
                  </div>
                </div>
              </b-nav-item>
            </template>
            <template v-else>
              <b-nav-item :to="'/our-sports/' + dis.slug" @click="myFunction()">
                <div>
                  <div class="">
                    <b-img alt="image" :src="dis.acf.image">
                    </b-img>
                    <div class="acfTitle" :class="dis.slug + 'Back'">
                      <span class="spanacfTitle">{{dis.acf.title}}</span>
                    </div>
                  </div>
                </div>
              </b-nav-item>
            </template>
          </b-col>
        </b-row>
      </template>
      <template v-else-if="this.$route.name === 'inspired-to-try'">
        <b-row>
          <b-col
            class="disCol"
            lg="4"
            md="12"
            v-for="(dis, index) in disciplines.slice().reverse()"
            :key="index"
          >
          <template v-if="dis.acf.title == 'Volunteering'">
            <b-nav-item :to="'/volunteers'" >
              <div class="example">
                <b-img alt="image" :src="dis.acf.inspired_images"></b-img>
                <!-- <div class="overlay">
                </div> -->
                <br />
                <div
                  class="title"
                  :style="'background-color:' + '#dc772d' + ';'"
                >
                  <span class="db" :class="{purple : dis.acf.title === 'Open Water Swimming' || dis.acf.title === 'swimming' || dis.acf.title === 'Artistic Swimming'}">
                    {{ dis.acf.title }}
                    <!-- <font-awesome-icon class="icon" :icon="['fa', 'arrow-right']"/> -->
                  </span>
                </div>
              </div>
            </b-nav-item>
          </template>
          <template v-else>
            <b-nav-item :to="'/our-sports/' + dis.slug" >
              <div class="example">
                <b-img alt="image" :src="dis.acf.inspired_images"></b-img>
                <!-- <div class="overlay">
                </div> -->
                <br />
                <div
                  class="title"
                  :style="'background-color:' + dis.acf.button_colour + ';'"
                >
                  <span class="db" :class="{purple : dis.acf.title === 'Open Water Swimming' || dis.acf.title === 'swimming' || dis.acf.title === 'Artistic Swimming'}">
                    {{ dis.acf.title }}
                    <!-- <font-awesome-icon class="icon" :icon="['fa', 'arrow-right']"/> -->
                  </span>
                </div>
              </div>
            </b-nav-item>
          </template>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <h1 class="SportsHead">Our Sports</h1>
        <p class="SportsSection">Scottish Swimming is the National Governing Body for Swimming, Para Swimming, Diving, High Diving, Open Water Swimming, Water Polo, Artistic Swimming and Masters Swimming in Scotland. Choose your aquatic sport to find out more.</p>
        <b-row>
          <b-col
            class="disCol"
            lg="4"
            md="12"
            v-for="(dis, index) in disciplines.slice().reverse()"
            v-show="dis.acf.title != 'Volunteering'"
            :key="index"
          >
            <b-nav-item :to="'/our-sports/' + dis.slug" >
              <div class="example">
                <b-img alt="image" :src="dis.acf.image"></b-img>
                <!-- <div class="overlay">
                </div> -->
                <br />
                <div
                  class="title"
                  :style="'background-color:' + dis.acf.button_colour + ';'"
                >
                  <span class="db" :class="{purple : dis.acf.title === 'Open Water Swimming' || dis.acf.title === 'swimming' || dis.acf.title === 'Artistic Swimming'}">
                    {{ dis.acf.title }}
                    <!-- <font-awesome-icon class="icon" :icon="['fa', 'arrow-right']"/> -->
                  </span>
                </div>
              </div>
            </b-nav-item>
          </b-col>
        </b-row>
      </template>
    </div>
</template>

<script>
import apiCall from "@/services/api.js";
import { bus } from "../main.js";
export default {
  name: "disciplines",
  data() {
    return {
      disciplines: [],
      posts: [],
      pickDis: "",
      open: ''
    };
  },
  methods: {
    setDis(picked) {
      this.pickDis = picked;
      bus.$emit("Picked", picked);
    },
  myFunction : function() {
   var element = document.getElementById("myDIV");
   element.classList.add("mystyle");
  }
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/disciplines")
      .then(({ data }) => {
        this.disciplines = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  created() {
    apiCall
      .wpInfo("wp/v2/posts?categories=274&orderby=date")
      .then(({ data }) => {
        this.posts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      }); 
  }
};
</script>

<style scoped>
/* .example .overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.1);
} */
.purple {
  color: #fff;
}
h1.SportsHead {
  color: #1f1545;
  padding-bottom: 20px;
  margin-left: -13px;
  /* font-weight: 600; */
  font-family: GT-Haptik-Medium;
}
p.SportsSection {
  color: #1f1545;
  margin-left: -10px;
  padding-bottom: 30px;
}
.acfTitle {
  color: #fff;
  position: absolute;
  top: 38%;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
  font-weight: bold;
  z-index: 999;
  text-transform: capitalize;
  font-size: 14pt;
}
span.spanacfTitle {
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
}
span.spanacfTitle:hover {
  position: absolute;
  top: 40%!important;
  bottom: 0;
  left: 0;
  right: 0;
}
.swimmingBack:hover {
  background-color: #009fe3;
  height: 95%;
  position: absolute;
  top: 0;
  width: 97%;
}
.para-swimmingBack:hover {
  background-color: #afca0b;
  height: 95%;
  position: absolute;
  top: 0;
  width: 97%;
}
.water-poloBack:hover {
  background-color: #349980;
  height: 95%;
  position: absolute;
  top: 0;
  width: 97%;
}
.mastersBack:hover {
  background-color: #ff9901;
  height: 95%;
  position: absolute;
  top: 0;
  width: 97%;
}
.artistic-swimmingBack:hover {
  background-color: #f80240;
  height: 95%;
  position: absolute;
  top: 0;
  width: 97%;
}
.divingBack:hover {
  background-color: #6ec9df;
  height: 95%;
  position: absolute;
  top: 0;
  width: 97%;
}
.open-waterBack:hover {
  background-color: #5c439c;
  height: 95%;
  position: absolute;
  top: 0;
  width: 97%;
}
.featuredCols {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 50px!important;
  margin: auto;
}
img.rounded-0.eventImg.card-img {
  height: 260px;
  -o-object-fit: cover;
  object-fit: cover;
}
.card {
  background-color: #201546;
  color: #fff;
  border: 0px;
  border-radius: 0;
}
.datescard{
  font-size: 18px;
  font-weight: bold;
  padding: 20px 0;
  color: #009fe3;
}
.titlecard {
  font-size: 24px;
  font-weight: bold;
  padding: 20px 0;
}
.featuredNews {
  height: 280px;
  width: 100%;
}
.featBlock {
  border: 5px solid rgb(219, 4, 78);
}
.pad {
  padding: 50px 7px;
}
li {
  list-style: none;
}
.dis {
  padding: 5% 10%;
}
.disCol > .nav-item > .nav-link {
  padding: 0;
}
.disCol {
  padding: 0 5px 10px 5px;
}
.title {
  height: 66px;
  border-radius: 0px 33px 0px 0px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-top: -66px;
  position: absolute;
  padding: 20px;
  text-transform: capitalize;
  padding-right: 75px;
  transition: padding-right 0.5s;
}
.title:hover:after {
  opacity: 1;
  right: 40px;
  position: absolute;
}
.title:hover {
  padding-right: 140px;
}
.title:after {
  content: '»';
  opacity: 0;  
}
.mem{
  height: 231px;
}
li {
  list-style: none!important;
  color: #201546;
}
@media only screen and (max-width: 500px) {
  .mem{
    height: auto;
  }
  .title {
    font-size: 14px;
  }
}
</style>
