var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"borderTop"}),_c('b-row',{staticClass:"upcomingRow"},[(_vm.filterEvents.length === 0)?[_c('b-col',[_c('h2',{staticClass:"noEvent"},[_vm._v(" There are no upcoming events for this sport.. ")])])]:_vm._e(),_vm._l((_vm.filterEvents),function(event,index){return _c('b-col',{directives:[{name:"show",rawName:"v-show",value:(event.acf.event_done !== true),expression:"event.acf.event_done !== true"}],key:index,staticClass:"eve",attrs:{"lg":"12","md":"12","cols":"12"}},[_c('b-card',{staticClass:"overflow-hidden",attrs:{"no-body":""}},[_c('b-row',{staticClass:"desktopLayout",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"overHidden",attrs:{"md":"5"}},[(_vm.discipline === 'major-events' || event.acf.major_event === true)?[_c('b-nav-item',{attrs:{"to":'/our-sports/' +
                        event.acf.discipline[0] +
                        '/events/' +
                        event.slug}},[_c('div',{class:_vm.itemLink(event.acf.discipline[0]) + 'Back'},[(event.acf.discipline[0] === 'open-water')?_c('span',{class:{purple : event.acf.discipline[0] === 'open-water'}},[_vm._v(" Open Water ")]):(event.acf.discipline[0] === 'artistic-swimming')?_c('span',{class:{purple : event.acf.discipline[0] === 'artistic-swimming'}},[_vm._v(" Artistic Swimming ")]):_c('span',[_vm._v(" "+_vm._s(event.acf.discipline[0])+" ")])])])]:[_c('b-nav-item',{attrs:{"to":'/our-sports/' +
                        event.acf.discipline[0] +
                        '/events/' +
                        event.slug}},[_c('div',{class:_vm.itemLink(event.acf.discipline[0]) + 'Back'},[(event.acf.discipline[0] === 'open-water')?_c('span',{class:{purple : event.acf.discipline[0] === 'open-water'}},[_vm._v(" Open Water ")]):(event.acf.discipline[0] === 'artistic-swimming')?_c('span',{class:{purple : event.acf.discipline[0] === 'artistic-swimming'}},[_vm._v(" Artistic Swimming ")]):_c('span',[_vm._v(" "+_vm._s(event.acf.discipline[0])+" ")])])])],_c('b-link',{staticClass:"EventLink",attrs:{"to":'/our-sports/' +
                      event.acf.discipline[0] +
                      '/events/' +
                      event.slug}},[_c('b-card-img',{staticClass:"rounded-0 eventImg",attrs:{"src":event.acf.image,"alt":"Image"}})],1)],2),_c('b-col',{attrs:{"md":"7"}},[_c('b-link',{staticClass:"EventLink",attrs:{"to":'/our-sports/' +
                      event.acf.discipline[0] +
                      '/events/' +
                      event.slug}},[_c('b-card-body',[_c('b-card-text',[_c('h3',{staticClass:"dates"},[_vm._v(" "+_vm._s(event.acf.dates)+" ")]),_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(event.acf.title)+" ")]),_c('h3',{staticClass:"venue"},[_vm._v(" "+_vm._s(event.acf.venue)+" ")])])],1)],1)],1)],1),_c('b-row',{staticClass:"mobileLayout",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"md":"7"}},[_c('b-card-body',[_c('b-card-text',[_c('h3',{staticClass:"dates"},[_vm._v(" "+_vm._s(event.acf.dates)+" ")]),_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(event.acf.title)+" ")]),_c('h3',{staticClass:"venue"},[_vm._v(" "+_vm._s(event.acf.venue)+" ")])])],1)],1),_c('b-col',{staticClass:"overHidden",attrs:{"md":"5"}},[(_vm.discipline === 'major-events' || event.acf.major_event === true)?[_c('b-nav-item',{attrs:{"to":'/our-sports/' +
                        event.acf.discipline[0] +
                        '/events/' +
                        event.slug}},[_c('div',{class:_vm.itemLink(event.acf.discipline[0]) + 'Back'},[(event.acf.discipline[0] === 'open-water')?_c('span',{class:{purple : event.acf.discipline[0] === 'open-water'}},[_vm._v(" Open Water ")]):(event.acf.discipline[0] === 'artistic-swimming')?_c('span',{class:{purple : event.acf.discipline[0] === 'artistic-swimming'}},[_vm._v(" Artistic Swimming ")]):_c('span',[_vm._v(" "+_vm._s(event.acf.discipline[0])+" ")])])])]:[_c('b-nav-item',{attrs:{"to":'/our-sports/' +
                        event.acf.discipline[0] +
                        '/events/' +
                        event.slug}},[_c('div',{class:_vm.itemLink(event.acf.discipline[0]) + 'Back'},[(_vm.discipline === 'open-water')?_c('span',{class:{purple : _vm.discipline === 'open-water'}},[_vm._v(" Open Water ")]):(_vm.discipline === 'artistic-swimming')?_c('span',{class:{purple : _vm.discipline === 'artistic-swimming'}},[_vm._v(" Artistic Swimming ")]):_c('span',[_vm._v(" "+_vm._s(event.acf.discipline[0])+" ")])])])],_c('b-card-img',{staticClass:"rounded-0 eventImg",attrs:{"src":event.acf.image,"alt":"Image"}})],2)],1)],1)],1)})],2),_c('div',{staticClass:"borderBottom"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }